import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c418a9b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-chart__container" }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  class: "legends"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "legend-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.chartId)
        ? (_openBlock(), _createElementBlock("canvas", {
            key: 0,
            id: _ctx.chartId
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    (_ctx.legendItems && _ctx.legendItems.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legendItems, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.label,
                onClick: ($event: any) => (_ctx.changeFilter(item, index))
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", {
                    style: _normalizeStyle({ 'background-color': item.color })
                  }, null, 4),
                  _createElementVNode("span", {
                    class: _normalizeClass(["label", { disable: !item.visible }])
                  }, _toDisplayString(item.label), 3)
                ])
              ], 8, _hoisted_4))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}