import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-71481109"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-cgm__scores" }
const _hoisted_2 = { class: "c-efficiency-card" }
const _hoisted_3 = { class: "c-efficiency-card__title" }
const _hoisted_4 = {
  key: 0,
  class: "c-card"
}
const _hoisted_5 = { class: "c-card__units" }
const _hoisted_6 = { class: "c-card__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScoreCircular = _resolveComponent("ScoreCircular")!
  const _component_CardComponent = _resolveComponent("CardComponent")!

  return (_openBlock(), _createBlock(_component_CardComponent, {
    cardTitle: _ctx.$t('cgmFinalAnalysis.your-averages'),
    border: true
  }, {
    cardContent: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.efficiency)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["c-efficiency-card__wrapper", _ctx.scoreHelper.scoreColor(_ctx.efficiency.value)])
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ScoreCircular, {
                  class: "c-efficiency-card__circular-score",
                  score: _ctx.efficiency.value,
                  color: _ctx.scoreHelper.scoreColor(_ctx.efficiency.value)
                }, null, 8, ["score", "color"]),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('cgmFinalAnalysis.cgm-scores.EFFICIENCY')), 1)
              ])
            ], 2))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allOtherScores, (score) => {
          return (_openBlock(), _createElementBlock("div", {
            key: score.type,
            class: "c-card__wrapper"
          }, [
            (score.type !== 'EFFICIENCY')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["c-card__score", _ctx.scoreHelper.measurementColor(score)])
                  }, [
                    _createTextVNode(_toDisplayString(score.value ? score.value : '--') + " ", 1),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.scoreHelper.measurementUnit(score)), 1)
                  ], 2),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t(`cgmFinalAnalysis.cgm-scores.${score.type}`)), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["cardTitle"]))
}